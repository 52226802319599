.reviews-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center each review-box horizontally */
    gap: 20px;
    margin-top: 120px;
    margin-bottom: 100px;
}

.review-header {
    text-align: center;
    font-size: 38px;
    margin-bottom: 30px;
}

.review-box {
    padding: 40px;
    width: 40%;
    max-width: 1000px;
    background-color: white;
    height: auto; /* Adjust height dynamically based on content */
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    border-radius: 20px;
    border: 5px solid #a0d7f9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.review-box:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.review-box:active {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.review-name {
    color: #000000;
    margin-bottom: 7px;
    font-size: 24px;
    line-height: 1.23;
    font-weight: bold;
}

.review-description {
    margin-top: 35px;
    text-align: left;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.55;
    color: #333;
}

.review-image {
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    border-radius: 50%; /* Make it circular */
    object-fit: cover; /* Ensure image covers the circle */
    margin: 10px 0; /* Add some space between the name and image */
}

@media (max-width: 768px) {
    .review-box {
        width: 90%; /* More suitable for mobile */
        padding: 20px; /* Less padding for smaller screens */
    }
    .review-description {
        font-size: 16px; /* Smaller font size for better readability on mobile */
    }
}
