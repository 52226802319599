/* BlurModal.css */
.blur-modal {
    padding-top: 50px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    visibility: hidden; /* Hidden by default */
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 81; /* Ensure it's high enough */
}

.blur-modal.open {
    visibility: visible;
    opacity: 1;
}

.blur-modal-content-form-three {
    padding: 0px;
    background: transparent; /* Set background to transparent */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1); /* Consider if you still want the shadow with a transparent background */
    animation: fadeIn 0.6s ease-in-out;
    pointer-events: auto; /* Ensure interactions are allowed */
}

@keyframes fadeIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

@media (max-width: 768px) {
    .blur-modal {

    }

    .blur-modal-content {
        transform: scale(0.65);
        border-radius: 80px;
        margin-bottom: 40px;
    }

    .blur-modal-content-form-three {
        transform: scale(0.8);
        border-radius: 80px;
        margin-bottom: 40px;
    }
}
