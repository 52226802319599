/* src/TypingAnimation.css */

.cursor {
    display: inline-block;
    width: 0.1em; /* Width of the cursor */
    background-color: white; /* Cursor color */
    animation: blink 0.7s step-start infinite;
}

@keyframes blink {
    50% {
        background-color: transparent;
    }
}

@media (max-width: 768px) {
    /*transform: scale(0.8);*/
    .typing-animation {
        /*transform: scale(0.5);*/
    }
}
